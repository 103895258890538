import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import './PursuitOfPassion.scss'
import ReactPlayer from 'react-player'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'
import Layout from '../../layouts/Layout/Layout'
import { PreviousNextTypes } from '../../components/ArticleNavigation/ArticleNavigation'
import ArticleLayout from '../../layouts/ArticleLayout/ArticleLayout'
import Seo from '../../components/Seo/Seo'
import getBannerData from '../../services/getBannerData'

const ContentComponents = loadable(() =>
  import('../../components/ContentComponents/ContentComponents'),
)

const ArticleNavigation = loadable(() =>
  import('../../components/ArticleNavigation/ArticleNavigation'),
)

type ArticleTypes = {
  seoTitle: string
  seoDescription: string
  title: string
  subtitle: string
  category: string
  featuredImage: string
  optimizedFeaturedImg: ImageDataLike
  videoLink: string
  slug: string
  datePublished: string
  shortDescription: string
  homeBanner?: {
    image: string
    videoLink: string
  }
  contents: {
    title?: string
    description?: string
    message?: string
    type: string
    video?: string
    caption?: string
    caption1?: string
    caption2?: string
    caption3?: string
    caption4?: string
    optimizedContentImg?: ImageDataLike
    optimizedContentImg1?: ImageDataLike
    optimizedContentImg2?: ImageDataLike
    optimizedContentImg3?: ImageDataLike
    optimizedContentImg4?: ImageDataLike
  }[]
  tags: string[]
}

type RecentArticleTypes = {
  id: string
  title: string
  slug: string
  category: string
  datePublished: string
  optimizedFeaturedImg: ImageDataLike
  featuredImage: string
}

type MainPropTypes = {
  data: {
    pursuitOfPassionData: ArticleTypes
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
  pageContext: {
    previous?: PreviousNextTypes
    next?: PreviousNextTypes
  }
}

const asideNavItems = [
  {
    url: 'pursuit-of-passion/people/',
    label: 'People',
    category: 'People',
  },
  {
    url: 'pursuit-of-passion/places/',
    label: 'Places',
    category: 'Places',
  },
  {
    label: 'Practical Living',
    category: 'Practical Living',
    items: [
      {
        url: 'pursuit-of-passion/practical-living/home-and-design/',
        label: 'Home & Design',
      },
      {
        url: 'pursuit-of-passion/practical-living/wellness/',
        label: 'Wellness',
      },
      {
        url: 'pursuit-of-passion/practical-living/travel-and-leisure/',
        label: 'Travel & Leisure',
      },
      {
        url: 'pursuit-of-passion/practical-living/finance/',
        label: 'Finance',
      },
    ],
  },
  {
    url: 'pursuit-of-passion/videos/',
    label: 'Videos',
    category: 'Videos',
  },
]

const PursuitOfPassion = ({
  data: {
    pursuitOfPassionData,
    recentArticles: { nodes: recentArticles },
  },
  pageContext: { previous, next },
}: MainPropTypes): ReactElement => {
  const [bannerMedia] = useState<{
    url?: string
    type: 'image' | 'video' | 'optimized'
    image?: ImageDataLike
  } | null>(
    getBannerData(
      pursuitOfPassionData.optimizedFeaturedImg,
      pursuitOfPassionData?.videoLink,
      pursuitOfPassionData?.homeBanner,
    ),
  )

  const seoTitle =
    pursuitOfPassionData.seoTitle &&
    pursuitOfPassionData.seoTitle !== 'no content'
      ? pursuitOfPassionData.seoTitle
      : pursuitOfPassionData.title

  const seoDescription =
    pursuitOfPassionData.seoDescription &&
    pursuitOfPassionData.seoDescription !== 'no content'
      ? pursuitOfPassionData.seoDescription
      : pursuitOfPassionData.shortDescription

  return (
    <Layout>
      <Seo
        title={seoTitle}
        ogMetaData={{
          title: seoTitle,
          description: seoDescription,
          image: pursuitOfPassionData.featuredImage,
        }}
        jsonData={{
          '@type': 'Article',
          description: seoDescription,
          keywords: pursuitOfPassionData.tags.join(', '),
          image: pursuitOfPassionData.featuredImage,
          url: `${process.env.GATSBY_SITE_URL}/pursuit-of-passion/${pursuitOfPassionData.slug}/`,
        }}
      />
      <ArticleLayout
        title="Categories"
        asideNavItems={asideNavItems}
        breadcrumbs={[
          {
            url: 'pursuit-of-passion',
            label: 'Pursuit of Passion',
          },
          {
            label: pursuitOfPassionData.category,
          },
        ]}
        recentArticles={recentArticles}
        displayRecentArticlesMobile
        contentClassName="pursuit-of-passion"
      >
        <article className="pursuit-of-passion">
          {bannerMedia && (
            <>
              {bannerMedia.type === 'video' ? (
                <ReactPlayer
                  url={bannerMedia.url}
                  width="100%"
                  height="100%"
                  className="pursuit-of-passion-banner"
                />
              ) : null}
              {bannerMedia.type === 'optimized' ? (
                <GatsbyImage
                  image={getImage(bannerMedia.image!)!}
                  alt="image"
                />
              ) : (
                <LazyLoadImage
                  className="news-events-banner"
                  src={bannerMedia.url}
                  alt="banner image"
                />
              )}
            </>
          )}

          <div className="pursuit-of-passion-container">
            <span className="pursuit-of-passion-container-type">
              {pursuitOfPassionData.category}
            </span>
            <h2 className="pursuit-of-passion-container-title">
              {pursuitOfPassionData.title}
            </h2>
            <p className="pursuit-of-passion-container-date">
              {pursuitOfPassionData.datePublished}
            </p>
            <div className="pursuit-of-passion-container-content">
              <ContentComponents contents={pursuitOfPassionData.contents} />
            </div>
          </div>
        </article>
      </ArticleLayout>
      <ArticleNavigation
        title={pursuitOfPassionData.title}
        slug={pursuitOfPassionData.slug}
        previous={previous}
        next={next}
      />
    </Layout>
  )
}
export default PursuitOfPassion

export const pageQuery = graphql`
  query PursuitOfPassionQuery(
    $id: String!
    $pursuitofPassionCategories: [String]!
  ) {
    pursuitOfPassionData: pursuitOfPassion(id: { eq: $id }) {
      ...PursuitOfPassionPageArticleFields
    }
    recentArticles: allPursuitOfPassion(
      filter: { category: { in: $pursuitofPassionCategories } }
      limit: 3
    ) {
      nodes {
        ...PursuitOfPassionPageRecentArticleFields
      }
    }
  }
`
